import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Call from '@material-ui/icons/Call';
import Email from '@material-ui/icons/Email';
import LocationOn from '@material-ui/icons/LocationOn';
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import Instagram from '@material-ui/icons/Instagram';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Logo from './img/home.png';
import  {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
const useStyles=makeStyles((theme)=>({
    caro:{ 
        
      backgroundColor:'black',
      color:'white',
        padding:'50px',
       
    },
    back:{ 
        
      backgroundColor:'black',
      color:'gray',
        padding:'10px',
        fontSize:13
       
    }
}));














function Example(props){
    const classes=useStyles()
    return (
        
        <div className={classes.back}>
        <div className={classes.caro}>
        <Grid container spacing={8}>
        <Grid item xs={12} sm={5} md={5} align="center">
          <img src={Logo} width="50%" />
         {/* <h2 style={{textAlign:'center'}}> 3AM MISSION MINISTRY</h2> */}
        <Grid container spacing={5} style={{textAlign:'left'}}>
        <br/>
        
       
        </Grid>
        </Grid>
        <Grid item  xs={12} sm={3} md={3} >
    <h2 style={{textAlign:'left'}}> Quick Link</h2>
        
        
        <Grid container spacing={1} style={{textAlign:'center'}}>
        
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
            <Link to="/donate"> Donate  </Link>

         </Grid>
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
        <Link to="/aboutus">About Us</Link>

        
         </Grid>
        
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
       
       <Link to="/contactus">Contact Us</Link>

         </Grid>
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
        <Link to="/gallery">Gallery</Link>
         </Grid>
        </Grid>
    
        </Grid>
        
        
        
        <Grid item  xs={12} sm={4} md={4} >
        <h2 style={{textAlign:'left'}}> Contact Us</h2>
        
        <Grid container spacing={1} style={{textAlign:'left'}}>
       
         <Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}>
        <Email/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
        <a href='mailto:support@3ammissionministry.com'>support@3ammissionministry.com</a>
         </Grid><Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}>
        <Call/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
         <a href='tel:+144 38087618'>+144 38087618 </a>
         </Grid><Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}>
        <LocationOn/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
         <a href='https://goo.gl/maps/kuEJyQHjEerrqNYR7'>1011 Winged Foot Drive, Bowie, Maryland, 20721, USA</a>
         </Grid>

         <Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Facebook/>
         </Grid>
<Grid item xs={10} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <a href="https://web.facebook.com/search/top?q=3am%20mission%20ministries"> Facebook</a>        
         </Grid>

         <Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Instagram/>
         </Grid>
<Grid item xs={10} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <a href="https://www.instagram.com/3ammission/"> Instagram</a>        
         </Grid>

         <Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Twitter/>
         </Grid>
<Grid item xs={10} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <a href="https://twitter.com/3amMission"> Twitter</a>        
         </Grid>
        </Grid>
    
        </Grid>
        
        

      </Grid>
 </div>
     <p style={{textAlign:'center'}}>
      Copyright 2022-2025 3AM Mission Ministry all rights reserved <br/>
      Powered by <a  href="http://ravinlead.com">RavinLead Tech Hub</a>
</p>
        </div>
        
   
        
        
    

    )
}


export default Example


